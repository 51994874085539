import React from 'react';
import { graphql, PageProps } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
// eslint-disable-next-line no-unused-vars
import tw, { styled } from 'twin.macro';

import useCompanyInfo from '../../hooks/use-company-info';
import useFloatTrips from '../../hooks/use-float-trips';
import { ImageSlide } from '../../entities/image-slide';
import PageTemplate from '../../components/page-template';
import GuideSubNav from '../../components/guide-sub-nav';
import TripCard from '../../components/trip-card';
import SEO from '../../components/seo';
import ImageSlider from '../../components/image-slider';

import {
  FlexContainer,
  PageSection,
  TextContainer,
  TitleBar,
  PageTitle,
} from '../../components/ui-elements';

const BookCallout = styled.div`
  ${tw`text-center bg-primary-300 text-primary-100 py-4 mb-8`};
`;

type GraphQLData = {
  page: {
    seo: {
      title: string;
      description: string;
      keywords: string[];
    };
    _rawBody: string;
    lightbox: {
      lightboxDisplay: string;
      slides: ImageSlide[];
    };
  };
};

type FloatTripsPageProps = PageProps<GraphQLData>;

const FloatTripsPage = (props: FloatTripsPageProps) => {
  const { data } = props;
  const {
    page: { seo, _rawBody, lightbox },
  } = data;
  const { phoneNum } = useCompanyInfo();
  const { fullDayFloat, halfDayFloat } = useFloatTrips();

  return (
    <PageTemplate>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <PageTitle tw="text-primary-100">Guided Fly Fishing Trips</PageTitle>
      </TitleBar>
      <GuideSubNav activeLink="float-trip" />
      <PageSection>
        <PageTitle>{seo.title}</PageTitle>
        <FlexContainer>
          <div tw="md:w-2/3 md:pr-8">
            <ImageSlider lightbox={lightbox} interval={4000} size="full" />
            <TextContainer tw="md:leading-relaxed pt-12">
              <BlockContent blocks={_rawBody} />
            </TextContainer>
          </div>
          <div tw="w-full md:w-1/3 md:pl-8">
            <BookCallout>
              <PageTitle tw="md:text-center text-primary-100 pb-0">
                Call to Book a Trip
              </PageTitle>
              <a href={`tel:${phoneNum}`} tw="text-2xl">
                {phoneNum}
              </a>
            </BookCallout>
            <div tw="w-full mb-8">
              <TripCard
                title="half day float"
                tripDuration={halfDayFloat.tripDuration}
                included={halfDayFloat.included}
                excluded={halfDayFloat.excluded}
                pricing={halfDayFloat.pricing}
              />
            </div>
            <div tw="w-full mb-8">
              <TripCard
                title="full day float"
                tripDuration={fullDayFloat.tripDuration}
                included={fullDayFloat.included}
                excluded={fullDayFloat.excluded}
                pricing={fullDayFloat.pricing}
              />
            </div>
          </div>
        </FlexContainer>
      </PageSection>
    </PageTemplate>
  );
};

export default FloatTripsPage;

export const query = graphql`
  query FloatTripsPageQuery {
    page: sanityFloatTripsPage {
      seo {
        title
        description
        keywords
      }
      _rawBody
      lightbox {
        lightboxDisplay
        slides {
          _key
          alt
          caption
          asset {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  }
`;
